@import url('https://fonts.googleapis.com/css2?family=Space+Mono:wght@700&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Poppins&display=swap');

// FONTS
$font-header: "Space Mono";
$font-main: "Poppins";


// COLORS
// Text Colors
$header-primary-dark: #FFD700;
$header-primary-light: #FF7597;
$text-primary: #BB86FC;
$text-secondary: #03DAC5;
$text-warning: #dc3545;
$background-dark-primary: #1f1e21;

$btn-background: #24a0ed;
$white: #ffffff;
$black: #000000;

:root {
  /* COLOR VARIABLE */
  /* Black */
  --black-1: #000000;
  --black-2: #1a1a1a;
  --black-3: #333333;
  --black-4: #4d4d4d;
  --black-5: #666666;
  --black-6: #808080;
  --black-7: #999999;
  --black-8: #b3b3b3;
  --black-9: #cccccc;
  --black-10: #e6e6e6;

  /* White */
  --white-1: #ffffff;
  --white-2: #f0f0f0;
  --white-3: #e0e0e0;
  --white-4: #d1d1d1;
  --white-5: #c2c2c2;
  --white-6: #b3b3b3;
  --white-7: #a3a3a3;
  --white-8: #949494;
  --white-9: #858585;
  --white-10: #767676;

  /* Yellow */
  --yellow-1: #ffff00;
  --yellow-2: #ffea00;
  --yellow-3: #ffd700;
  --yellow-4: #ffc000;
  --yellow-5: #ffab00;
  --yellow-6: #ff9600;
  --yellow-7: #ff8100;
  --yellow-8: #ff6c00;
  --yellow-9: #ff5700;
  --yellow-10: #ff4200;

  /* Red */
  --red-1: #ff0000;
  --red-2: #e60000;
  --red-3: #cc0000;
  --red-4: #b30000;
  --red-5: #990000;
  --red-6: #800000;
  --red-7: #660000;
  --red-8: #4d0000;
  --red-9: #330000;
  --red-10: #1a0000;

  /* Blue */
  --blue-1: #adadff;
  --blue-2: #0000e6;
  --blue-3: #0000cc;
  --blue-4: #0000b3;
  --blue-5: #000099;
  --blue-6: #000080;
  --blue-7: #000066;
  --blue-8: #00004d;
  --blue-9: #000033;
  --blue-10: #00001a;

  /* Purple */
  --purple-1: #bc08bc;
  --purple-2: #730073;
  --purple-3: #660066;
  --purple-4: #590059;
  --purple-5: #4d004d;
  --purple-6: #400040;
  --purple-7: #330033;
  --purple-8: #260026;
  --purple-9: #1a001a;
  --purple-10: #0d000d;
  --purple-alt-1: #9d4dff;
  --purple-alt-2: #BB86FC;

  /* Green */
  --green-1: #008000;
  --green-2: #007300;
  --green-3: #006600;
  --green-4: #005900;
  --green-5: #004d00;
  --green-6: #004000;
  --green-7: #003300;
  --green-8: #002600;
  --green-9: #001a00;
  --green-10: #000d00;

  /* Pink */
  --pink-1: #ffc0cb;
  --pink-2: #ffb3c1;
  --pink-3: #ffa6b8;
  --pink-4: #ff99ae;
  --pink-5: #ff8ca5;
  --pink-6: #ff7f9c;
  --pink-7: #ff7292;
  --pink-8: #ff6589;
  --pink-9: #ff5880;
  --pink-10: #ff4c76;

  /* Gold */
  --gold-1: #ffd700;
  --gold-2: #ffcc00;
  --gold-3: #ffc200;
  --gold-4: #ffb800;
  --gold-5: #ffae00;
  --gold-6: #ffa400;
  --gold-7: #ff9900;
  --gold-8: #ff8f00;
  --gold-9: #ff8500;
  --gold-10: #ff7b00;

  /* Orange */
  --orange-1: #FFF5E0;
  --orange-2: #FFE0B2;
  --orange-3: #FFD180;
  --orange-4: #FFBB33;
  --orange-5: #FFA726;
  --orange-6: #FF9800;
  --orange-7: #FB8C00;
  --orange-8: #F57C00;
  --orange-9: #EF6C00;
  --orange-10: #E65100;
}


// Main app styling
body {
  // General Variables
  // Color
  --warning-text: var(--yellow-2);
  --error-text: var(--red-2);
  --success-text: var(--green-1);
  --info-text: var(--blue-2);
  --link-color: #03DAC5;
  --highlight-primary: var(--pink-7);

  // Other
  --border-radius: 5px;

  // Dark Mode Specific Variables
  --bg: #1f1e21;
  --header-text-primary: var(--gold-1);
  --header-text-secondary: var(--pink-6);
  --header-text-accent: var(--purple-alt-1);
  --content-text-primary: var(--pink-7);
  --content-text-secondary: var(--pink-7);
  --content-text-accent: var(--white-1);
  --dropdown-color: var(--white-1);
  --dropdown-link-bg: #1f1e21;
  --nav-link-hover-bg: var(--black-4);
  --project-card-bg: var(--black-3);
  --project-card-hover-bg: var(--black-4);
  --project-card-dropdown-bg: var(--black-2);
  --project-card-title: var(--white-1);
  --project-card-text: var(--white-3);
  --footer-icon: var(--gold-1);
  --bubble-shadow: var(--white-8);
  --bubbleBorder-1: var(--blue-7);
  --bubbleBorder-2: var(--purple-7);
  --bubbleBorder-3: var(--gold-1);
  --bubbleBorder-4: var(--purple-1);
  --bubbleBorder-5: var(--white-1);

  // Properties
  background-color: var(--bg);
}
body.light {
  // Light Mode Specific Variables
  --bg: var(--white-1);
  --header-text-primary: var(--purple-alt-1);
  --header-text-secondary: var(--black-1);
  --header-text-accent: var(--black-1);
  --content-text-primary: var(--purple-7);
  --content-text-secondary: var(--pink-7);
  --content-text-accent: var(--black-1);
  --dropdown-color: var(--black-1);
  --dropdown-link-bg: var(--white-1);
  --nav-link-hover-bg: var(--white-3);
  --project-card-bg: var(--white-3);
  --project-card-hover-bg: var(--white-4);
  --project-card-dropdown-bg: var(--white-2);
  --project-card-title: var(--black-1);
  --project-card-text: var(--black-3);
  --footer-icon: var(--purple-alt-1);
  --bubble-shadow: rgba(0,0,0,.16);
  --bubbleBorder-1: var(--pink-1);
  --bubbleBorder-2: var(--purple-1);
  --bubbleBorder-3: var(--gold-3);
  --bubbleBorder-4: var(--blue-1);
  --bubbleBorder-5: var(--white-1);
  
  // Properties
  background-color: var(--bg)
}

.app {
  display: flex;
  flex-direction: column;
  font-family: $font-main;
}


/* ******************************************************************** */
.layout-container {
  position: relative;
  min-height: 100vh;
}

.header-content-wrapper {
  padding-bottom: 6rem;
}

// VIEW CONTAINER
.view-content {
  padding: 0 1rem;
  // margin-bottom: 8rem;
  color: var(--header-text-secondary);
}

/* ~~ VIEWS ~~ */

// HOME
.home {
  margin: 0 5%;
  color: $text-primary;
  h3 {
    color: $header-primary-light;
  }
}

.home_image {
  color: black;
  width: 100rem;
  height: 100rem;
}



// PROJECTS
.page-header {
  color: var(--header-text-secondary);
  text-align: left;
}

.project-card-container {
  display: flex;
  flex-direction: column;
  gap: .5rem;
  
}

.project-section-container > h2 {
  text-align: start;
  color: var(--header-text-primary);
}

/* ~~ COMPONENTS ~~ */
// HEADER
.header {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  padding: 1rem;
}

.header-title {
  display: flex;
  align-items: center;
  // padding-left: 1rem;
  height: min-content;
}

.home-title-link {
  text-decoration: none;
  color: var(--header-text-primary);
  font-family: $font-header;
  text-align: center;
  font-size: 1.8rem;

}

.header-options {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  // width: 10%;
}

// NAVBAR
.navbar {
  display: flex;
  flex-direction: column;
  align-items: flex-end;
  position: static;
  top: 1rem;
  right: 1rem;
  z-index: 1;
}

.nav-menu-icon {
  color: var(--dropdown-color);
  height: 42px;
  width: 42px;
}

.nav-menu-icon:hover {
  cursor: pointer;
}

.nav-items {
  display: flex;
  flex-direction: column;
  align-items: flex-end;
  width: min-content;
  margin-top: 50px;
  position: absolute;
  gap: .2rem;
  padding-bottom: .8rem;
  background-color: var(--dropdown-link-bg);
  border-radius: var(--border-radius);
}

.nav-link {
  width: 100%;
  text-align: end;
  text-decoration: none;
  font-weight: bold;
  font-size: x-large;
  color: var(--link-color);
  padding: .4rem;
}

.nav-link:hover {
  background-color: var(--nav-link-hover-bg);
  border-radius: var(--border-radius);
}


// THEME SWITCH
.theme-switch {
  width: 60px;
  height: 30px;
  border-radius: 15px;
  background-color: #ccc;
  position: relative;
  cursor: pointer;
  transition: background-color 0.3s ease;
  margin: .4rem;
}

.theme-switch.dark {
  background-color: var(--link-color)
}

.theme-switch.light {
  background-color: var(--gold-1);
}

.theme-slider {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 26px;
  height: 26px;
  border-radius: 50%;
  background-color: white;
  position: absolute;
  top: 2px;
  left: 2px;
  transition: transform 0.3s ease;
}

.theme-switch.dark .theme-slider {
  transform: translateX(30px);
}

.theme-icon {
  padding: 1px;
}

// FOOTER
.footer {
  position: absolute;
  bottom: 0;
  opacity: 96%;
  width: 100%;
  height: 6rem;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  text-align: center;
  color: var(--header-text-accent);
  background-color: var(--bg);
}

.footer_icons {
  width: 50%;
  display: flex;
  justify-content: space-around;
}

.footer_icon {
  // padding: 1rem;
  color: var(--footer-icon);
  :hover {
    cursor: pointer;
  }
}

.footer_year {
  text-decoration: none;
  color: var(--header-text-accent);
}

// PROJECT CARD
.project-card{
  display: flex;
  flex-direction: column;
  background-color: var(--project-card-bg);
  border-radius: var(--border-radius);
  height: min-content;
}

.project-dropdown-icon {
  color: var(--content-text-secondary);
  background-color: var(--project-card-dropdown-bg);
  height: 30px;
  width: auto;
}

.project-dropdown-icon:hover {
  cursor: pointer;
  border-radius: var(--border-radius);
  background-color: var(--white-4);
}

.project-link {
  text-decoration: none;
  text-align: center;
}

.project-link:hover {
  background-color: var(--project-card-hover-bg);
  border-radius: var(--border-radius);
}

.project-link > h1 {
  color: var(--project-card-title);
  font-weight: bold;
  font-size: 24px;
}

.project-link > p {
  color: var(--project-card-text);
  font-size: 20px;
  padding: 0 6%;
}

// IMAGE GEN
.image-gen-container {
  display: flex;
  justify-content: center;
  align-items: center;
}

.image-gen {
  width: 98%;
}

.bubble-container {
  display: flex;
  justify-content: center;
  align-items: center;
  min-height: 60vh;
  margin-top: 2rem;
}

.bubble {
  position: absolute;
  width: 280px;
  height: 280px;
  border-radius: 50%;
  box-shadow: inset 0 0 25px var(--bubble-shadow);
  animation: animate 10s ease-in-out infinite;
}

.bubble:nth-child(2) {
  position: relative;
  zoom: .4;
  left: -100px;
  top: -80px;
  animation-delay: -6s;
}
.bubble:nth-child(3) {
  position: relative;
  zoom: .6;
  right: -100px;
  top: -120px;
  animation-delay: -10s;
}

@keyframes animate {
  0%,100% {
    transform: translateY(-100px);
  }
  50% {
    transform: translateY(100px);
  }
}

.bubble span {
  position: absolute;
  border-radius: 50%;
}

.bubble span:nth-child(1) {
  inset: 10px;
  border-left: 15px solid var(--bubbleBorder-1);
  filter: blur(10px);
}
.bubble span:nth-child(2) {
  inset: 10px;
  border-right: 15px solid var(--bubbleBorder-2);
  filter: blur(14px);
}
.bubble span:nth-child(3) {
  inset: 20px;
  border-top: 15px solid var(--bubbleBorder-3);
  filter: blur(10px);
}
.bubble span:nth-child(4) {
  inset: 30px;
  border-left: 15px solid var(--bubbleBorder-4);
  filter: blur(12px);
}
.bubble span:nth-child(5) {
  inset: 10px;
  border-bottom: 10px solid var(--bubbleBorder-5);
  filter: blur(8px);
  transform: rotate(300deg);
}

/* ******************************************************************** */

// WINGSs
.input_text {
  margin: 10rem 8rem;
  overflow: hidden;
}

// FORM - Specific
.signup {
  display: flex;
  flex-direction: column;
  p {
    font-size: 2rem;
  }
}

.login {
  display: flex;
  flex-direction: column;
  p {
    font-size: 2rem;
  }
}

// FORM - Generic
.form {
  display: flex;
  flex-direction: column;
  margin: 1rem;
}

.input_section {
  display: flex;
  flex-direction: column;
  margin: 1.5rem;
  input {
    width: 100%;
    height: 2rem;
    font-size: 1.5rem;
    width: 100%;
  }
  
}

.input_label_section {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  label {
    color: $text-primary;
    font-size: 1.5rem;
    padding-bottom: 1rem;
  }
  .input_warning_message {
    font-size: 1rem;
    color: $text-warning;
    font-weight: 300;
    height: 1rem;
  }
}

.form_submit_btn {
  font-size: 1.5rem;
  border-radius: 2rem;
  border-style: none;
  background-color: $btn-background;
  color: white;
  margin-top: 2rem;
  padding: 1rem 2rem;
  width: fit-content;
  justify-self: center;
  align-self: center;
  :hover {
    cursor: grab/pointer;
    background-color: black;
  }
}

.link_primary {
  font-size: 2rem;
  color: $text-primary;
  text-align: center;
}
.question_primary {
  font-size: 2rem;
  text-align: center;
  color: $header-primary-light
}


/* ******************************************************************** */
// Larger Width Screens
@media screen and (min-width: 790px) {
  .header-content-wrapper {
    padding-bottom: 1rem;
  }

  .view-content {
    padding: 0 1rem;
    margin-bottom: 10rem;
    color: var(--header-text-secondary);
  }

  .project-card-container {
    display: grid;
    grid-template-columns: 1fr 1fr 1fr;
  }

  // Login & Signup Screens
  .login, .signup {
    justify-content: center;
    align-items: center;
  }
  
  // Login & Signup forms
  .form {
    width: 26rem;
    .input {
      font-size: 1rem;
    }
  }
  .input_section {
    margin: 1rem;
  }
  .form_submit_btn {
    font-size: 1rem;
    border-radius: .2em;
    padding: .4rem 1rem;
    width: 80%;
  }
  .input_label_section {
    label {
      font-size: 1.2rem;
    }
  }
}
